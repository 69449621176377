<template>
  <div>
    <div class="app-loader-container">
      <div class="app-loader-outer">
        <div class="app-loader-inner">
          <div 
            v-if="completedSteps <= 0"
            class="app-loader-pre fw-medium"
          >
            <div class="app-loader-loading">
              {{ $filters.localizeFilter('Loading') }}
            </div>
            
            <div class="app-loader-dots">
              {{ dots[dotIndex] }}
            </div>
          </div>
          
          <div 
            v-else
            class="app-loader-text fw-semibold"
          >
            <div class="app-loader-numbers">
              <div class="app-loader-number">
                <span v-if="+percentStr[0]">{{ +percentStr[0] }}</span>
              </div>
              <div class="app-loader-number">
                <span v-if="+percentStr[0] || +percentStr[1]">{{ +percentStr[1] }}</span>
              </div>
              <div class="app-loader-number">
                <span v-if="+percentStr[0] || +percentStr[1] || +percentStr[2]">{{ +percentStr[2] }}</span>
              </div>
            </div>
            <div class="app-loader-percent">
              <span v-if="+percentStr[0] || +percentStr[1] || +percentStr[2]">%</span>
            </div>
          </div>
        </div>
      </div>

      <svg
        class="app-loader-svg"
        xmlns="http://www.w3.org/2000/svg"
        width="200px"
        height="200px"
        version="1.1"
      >
        <defs>
          <linearGradient id="radialGradient">
            <stop
              offset="0%"
              stop-color="#6EE7B7"
            />

            <stop
              offset="100%"
              stop-color="#60A5FA"
            />
          </linearGradient>
        </defs>

        <circle 
          class="app-loader-circle"
          :r="completedSteps > 0 ? '90' : '0'"
          cx="100"
          cy="100"
          stroke-linecap="round" 
          :stroke-dashoffset="strokeDashoffset"
        />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppLoaderComponent',
  props: {
    steps: {
      type: Number,
      default: 100
    },
    completedSteps: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    strokeDashoffset: 564,
    completed: 0,
    interval: null,
    dotsInterval: null,
    dots: ['...', '..', '.', '..'],
    dotIndex: 0
  }),
  computed: {
    online () {
      return this.$store.getters.online
    },
    percent () {
      return  Math.floor(this.completed)
    },
    percentStr () {
      let percentStr = this.percent + ''
      if (!percentStr.length) {
        percentStr = '000'
      } else if (percentStr.length < 2) {
        percentStr = '00' + percentStr
      } else if (percentStr.length < 3) {
        percentStr = '0' + percentStr
      }
      return percentStr
    }
  },
  watch: {
    completedSteps () {
      if (!this.interval) {
        this.interval = setInterval(this.count, 1)
      }
    },
    completed (val) {
      if (val >= 100) {
        this.$emit('done')
      }
    }
  },
  created() {
    if (this.completedSteps <= 0) {
      this.dotsInterval = setInterval(() => {
        if (this.dotIndex < this.dots.length - 1) {
          this.dotIndex++
        } else {
          this.dotIndex = 0
        }
      }, 750)
    }
  },
  beforeUnmount() {
    this.stopInterval()

    if (this.dotsInterval) {
      clearInterval(this.dotsInterval)
      this.dotsInterval = null
    }
  },
  methods: {
    stopInterval () {
      if (this.interval) {
        clearInterval(this.interval)
        this.interval = null
      }
    },
    count() {
      const completed = Math.ceil((this.completedSteps * 100) / this.steps)

      if (this.dotsInterval) {
        clearInterval(this.dotsInterval)
        this.dotsInterval = null
      }

      if (this.completed < completed) {
        this.completed += 0.5 // 1

        if (this.strokeDashoffset >= 2.82) {
          this.strokeDashoffset -= 2.82
        } else if (this.strokeDashoffset < 2.82) {
          this.strokeDashoffset = 0
        }
      } else if (this.completed < 99 && this.online) {
        this.completed += 0.005 // 1

        if (this.strokeDashoffset >= 0.0282) {
          this.strokeDashoffset -= 0.0282
        } else if (this.strokeDashoffset < 0.0282) {
          this.strokeDashoffset = 0
        }
      } else {
        this.stopInterval()
      }
    }
  }
}
</script>
