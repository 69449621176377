<template>
  <button
    ref="button"
    class="modal-button overflow-dots fw-medium"
    :class="{
      'save-button-active modal-button-hover': clickable,
      'save-button-inactive cursor-not-allowed': !clickable
    }"
    @click.prevent.stop="buttonClicked()"
  >
    {{ text }}
  </button>
</template>

<script>
export default {
  name: 'SaveButtonComponent',
  props: {
    text: {
      type: String,
      default: ''
    },
    clickable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    buttonClicked() {
      setTimeout(() => {
        if (this.$refs.button && this.$refs.button.blur) { this.$refs.button.blur() }
      })
      
      this.$emit('clicked')
    }
  }
}
</script>