<template>
  <div class="notifications">
    <div
      v-for="notification of notifications"
      :key="notification.id"
      class="notification"
      :class="{
        'notification-info': !notification.type || notification.type === 'info',
        'notification-success': notification.type === 'success',
        'notification-warning': notification.type === 'warning',
        'notification-error': notification.type === 'error',
      }"
    >
      <div v-if="notification.icon">
        <div v-if="notification.icon === 'success'" class="notification-icon icon-check_circle" />
        <div v-else-if="notification.icon === 'error'" class="notification-icon icon-dangerous" />
        <div v-else-if="notification.icon === 'info'" class="notification-icon icon-info" />
        <div v-else-if="notification.icon === 'warning'" class="notification-icon icon-warning" />

        <div
          v-else
          :key="notification.icon + '-' + notification.id"
          class="notification-icon"
          :class="'icon-' + notification.icon"
        />
      </div>

      <div class="notification-text-container">
        <div class="notification-text color-grey fs-small">
          {{ notification.message }}
        </div>
      </div>

      <div
        v-if="!notification.eternal"
        :key="notification.eternal + '-' + notification.id"
        class="notification-close-button icon-close cursor-pointer"
        @click="toastify.remove(notification.id)"
      />
    </div>
  </div>
</template>

<script>
import useNotifications from '@/composables/useNotifications'

export default {
  setup() {
    const { notifications, toastify } = useNotifications()
    return { notifications, toastify }
  }
}
</script>
