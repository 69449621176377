<template>
  <button
    v-tooltip="{
      text: $filters.localizeFilter('Close'),
      padding: 2
    }"
    class="cursor-pointer"
    @click.prevent.stop="$emit('clicked')"
  >
    <div class="close-modal-icon icon-close" />
  </button>
</template>
