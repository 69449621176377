<template>
  <button
    v-if="helpsection && location"
    ref="button"
    v-tooltip="{
      text: $filters.localizeFilter('Help'),
      padding: 2,
      location: tooltipLocation
    }"
    class="cursor-pointer hover_opacity-0_75"
    @click.prevent.stop="goTo()"
  >
    <div class="icon-help square-1 icon-grey-second" />
  </button>
</template>

<script>
import { logAnalyticsEvent } from '@/firebase/analytics'

export default {
  name: 'GoToHelpComponent',
  props: {
    helpsection: {
      type: String,
      default: null
    },
    location: {
      type: String,
      default: null
    },
    tooltipLocation: {
      type: String,
      default: 'top'
    }
  },
  methods: {
    goTo() {
      if (this.helpsection && this.location) {
        setTimeout(() => {
          if (this.$refs.button && this.$refs.button.blur) { this.$refs.button.blur() }
        })

        window.open(`${this.$store.getters.helpLink}&helpsection=${this.helpsection}&location=${this.location}`, '_blank')

        logAnalyticsEvent('goToHelpClicked', { helpsection: this.helpsection, location: this.location })
      }
    }
  }
}
</script>