<template>
  <div
    id="app"
    :class="{'dark': useDarkMode}"
  >
    <component :is="layout" />
  </div>

  <AppNotifications />
</template>

<script>
import useNotifications from '@/composables/useNotifications'
import AppNotifications from '@/components/UI/AppNotifications'
import PublicLayout from '@/layouts/PublicLayout'
import PrivateLayout from '@/layouts/PrivateLayout'
import { setPageMetaTags } from '@/helpers/page-meta'

export default {
  setup () {
    const { toastify } = useNotifications()
    return { toastify }
  },
  components: {
    AppNotifications, PublicLayout, PrivateLayout
  },
  data: () => ({
    onlineToast: null,
    offlineToast: null,
    matchMedia: null,
    useDarkMode: false
  }),
  computed: {
    layout () {
      return (this.$route.meta.layout || 'public') + '-layout'
    },
    error () {
      return this.$store.getters.error
    },
    online () {
      return this.$store.getters.online
    },
    darkModeSettings () {
      return this.$store.getters.darkMode
    }
  },
  watch: {
    error (err) {
      if (err) {
        this.toastify.error(err)
        this.$store.commit('clearError')
      }
    },
    online (value) {
      if (value) {
        this.onlineToast = this.toastify.replace(this.offlineToast, this.$filters.localizeFilter('IsBackOnline'), 'success', {
          icon: 'wifi'
        })
      } else {
        this.offlineToast = this.toastify.replace(this.onlineToast, this.$filters.localizeFilter('NoInternetConnection'), 'error', {
          icon: 'wifi_off',
          timeout: null,
          eternal: true
        })
      }
    },
    darkModeSettings () {
      this.setMatchMedia()
      this.handleDarkModeStatus(this.matchMedia)
    }
  },
  created() {
    if (navigator.language) {
      this.$store.dispatch('newAppLanguage', { locale: navigator.language })
    }
    setPageMetaTags()
  },
  mounted() {
    window.addEventListener('online', this.handleOnlineStatus)
    window.addEventListener('offline', this.handleOnlineStatus)
    window.addEventListener('resize', this.handleResize)
    document.addEventListener('scroll', this.scrollHandler)

    this.setMatchMedia()

    if (this.matchMedia) { 
      this.matchMedia.addEventListener('change', this.handleDarkModeStatus) 
      this.handleDarkModeStatus(this.matchMedia)
    }
  },
  async beforeUnmount() {
    window.removeEventListener('online', this.handleOnlineStatus)
    window.removeEventListener('offline', this.handleOnlineStatus)
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('scroll', this.scrollHandler)

    if (this.matchMedia) { this.matchMedia.removeEventListener('change', this.handleDarkModeStatus) }
  },
  methods: {
    handleOnlineStatus () {
      this.$store.commit('setOnline', navigator.onLine)
    },
    removeAllNotKeepAliveTooltips () {
      const tooltips = document.getElementsByClassName('tooltip')
      for (const tooltip of tooltips) {
        const keepAlive = tooltip.getAttribute('data-tooltip-keep-alive')
        if (keepAlive !== true && keepAlive !== 'true' && tooltip.remove) { tooltip.remove() }
      }
    },
    handleResize () {
      this.removeAllNotKeepAliveTooltips()

      if (this.$store.getters.showResize) {
        this.$store.commit('setShowResize', false)
        setTimeout(() => {
          this.$store.commit('setShowResize', true)
        }, 300)
      }
    },
    scrollHandler () {
      this.removeAllNotKeepAliveTooltips()
    },
    setMatchMedia () {
      this.matchMedia = window.matchMedia('(prefers-color-scheme: dark)')
    },
    handleDarkModeStatus (event) {
      if (!event) { return }

      if (this.darkModeSettings === 'dark') {
        if (!this.useDarkMode) { this.useDarkMode = true }
      } else if (this.darkModeSettings === 'light') {
        if (this.useDarkMode) { this.useDarkMode = false }
      } else {
        if (event.matches && !this.useDarkMode) {
          this.useDarkMode = true
        } else if (!event.matches && this.useDarkMode) {
          this.useDarkMode = false
        }
      }
    }
  }
}
</script>

<style lang="scss">
  @import '@/assets/css/index.css';
</style>